// @flow

import { set, isEmpty } from 'lodash';
import { apiEndpoint } from './constants';

export default async function apiService({
  api,
  method,
  params,
  token,
  jsonResponse = true,
}) {
  const headers = {};

  const path = `${apiEndpoint}${api}`;

  set(headers, 'Accept', 'application/json');
  set(headers, 'Content-Type', 'application/json');
  if(token) {
    set(headers, 'Authorization', `Bearer ${token}`);
  }
  // set(headers, 'Access-Control-Expose-Headers', 'authorization');

  const reqBody = {
    method,
    headers,
  };

  if(!isEmpty(params)) {
    reqBody.body = JSON.stringify(params);
  }

  return fetch(path, reqBody)
    .then((response) => {
      if(jsonResponse) {
        return response.json();
      }
      return response.text();
    })
    .then(data => data)
    // eslint-disable-next-line
    .catch((error) => {
      return {
        result: 'error',
        message: 'Please check your internet connection!',
      };
    });
}
