import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import FileDownload from 'js-file-download';
import { Icon, Pagination } from 'antd';
import { connectAuth, connectUserlist, userListActionCreators } from 'core';
import promisify from '../../utilities';
import DropdownSelect from '../../components/DropdownSelect/DropdownSelect';
import ListItem from '../../components/ListItem/ListItem';
import ValidateWhitelist from './ValidateWhitelist';

const filterOptions = [
  { name: 'No Filter' },
  { name: 'True' },
  { name: 'False' },
];

const getFilterOption = (filterValue) => {
  switch(filterValue) {
    case 'No Filter':
      return -1;
    case 'True':
      return 1;
    case 'False':
      return 0;
    default:
      console.log('Unknown whitelist filter value');
      return -1;
  }
};

class DashboardContainer extends Component {
  web3 = null;

  static propTypes = {
    token: PropTypes.string.isRequired,
    getUserListCSV: PropTypes.any.isRequired,
    getWhitelisted: PropTypes.any.isRequired,
    postUserWhitelist: PropTypes.any.isRequired,
    getUserList: PropTypes.any.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      userList: null,
      whitelistedUsers: null,
      kycfilterOption: 'No Filter',
      whitefilterOption: 'No Filter',
      kycStatus: -1,
      whitelistStatus: -1,
      selectedIndex: -1,
      page: 1,
      pageSize: 15,
      searchKeyword: '',
    };
  }

  componentDidMount() {
    this.loadUserListPerPage(
      0,
      this.state.kycStatus,
      this.state.whitelistStatus,
      this.state.searchKeyword,
    );
  }

  onChangePagination = (page, pageSize) => {
    this.setState({ page, pageSize });
    this.loadUserListPerPage(
      page - 1,
      this.state.kycStatus,
      this.state.whitelistStatus,
      this.state.searchKeyword,
    );
  }

  checkWhitelist = () => {
    const { token, getWhitelisted } = this.props;
    const self = this;
    promisify(getWhitelisted, {
      token,
    })
      .then((res) => {
        self.setState({ whitelistedUsers: res.data });
      })
      .catch(e => console.log(e));
  }

  sendWhitelist = (userid, amount, bonus, force) => {
    const { token } = this.props;
    promisify(this.props.postUserWhitelist,
      {
        userid,
        bonusPercent: bonus,
        maxPurchaseAmount: amount,
        token,
        force,
      })
      .then((res) => {
        if(res.status === 'ok') {
        }
      })
      .catch((error) => {
      });
  }

  downloadUserContributions = async () => {
    const { token, getWhitelisted } = this.props;
    const users = await new Promise((resolve, reject) => {
      promisify(getWhitelisted, {
        token,
      })
        .then((res) => {
          resolve(res.data.users);
        })
        .catch(e => reject(e));
    });

    const tokenAddress = '0xDB3A07425122F2C9cAa97a8f7318FCC8318E4D94';

    const row = (u, contribution) => ([
      u.email || '', u.id, u.address || ' ', u.whitelistTxHash || ' ',
      u.allocation || ' ', contribution,
    ]);

    const csv = await Promise.all(users.map(async (user) => {
      let contribution = '?';
      if(user.address) {
        try {
          contribution = await new Promise((resolve, reject) => (
            fetch(`https://api.tokenbalance.com/balance/${tokenAddress}/${user.address}`, { method: 'GET' })
              .then(resp => resp.text())
              .then(data => resolve(data))
              .catch(e => reject(e))
          ));
        } catch(e) {
          contribution = 'error';
        }
      }
      return row(user, contribution).join();
    }));
    csv.unshift('Email,ID,Address,Whitelist TX,Allocation,Contribution\n');
    FileDownload(csv.join('\n'), `users-contributions_${users.length}.csv`);
  }

  downloadUserList = () => {
    const { token, getUserListCSV } = this.props;
    promisify(getUserListCSV, {
      page: this.state.page - 1,
      kyc: this.state.kycStatus,
      whitelist: this.state.whitelistStatus,
      search: this.state.searchKeyword,
      token,
    })
      .then((res) => {
        if(typeof res === 'string') {
          FileDownload(res, 'user.csv');
        } else {
          console.log('Download error');
        }
      })
      .catch(e => console.log(e));
  }

  onSelectItem = (filterValue, filterType) => {
    let { kycStatus, whitelistStatus } = this.state;
    if(filterType === 'kyc') {
      this.setState({ kycfilterOption: filterValue });
      kycStatus = getFilterOption(filterValue);
    } else if(filterType === 'whitelist') {
      this.setState({ whitefilterOption: filterValue });
      whitelistStatus = getFilterOption(filterValue);
    }

    this.setState({ kycStatus, whitelistStatus }, () => {
      this.loadUserListPerPage(
        0,
        this.state.kycStatus,
        this.state.whitelistStatus,
        this.state.searchKeyword,
      );
    });
  }

  onSelectList = (selectedIndex) => {
    this.setState({ selectedIndex });
  }

  handleSearchChange = (e) => {
    this.setState({ searchKeyword: e.target.value });
  }

  searchEmail = (e) => {
    if(e.keyCode === 13) {
      const searchKeyword = e.target.value;
      this.loadUserListPerPage(
        0,
        this.state.kycStatus,
        this.state.whitelistStatus,
        searchKeyword,
      );
    }
  }

  loadUserListPerPage(pageNum, kycStatus, whitelistStatus, searchKeyword) {
    const { token } = this.props;
    this.setState({ userList: null }, () => {
      promisify(this.props.getUserList, {
        page: pageNum,
        kyc: kycStatus,
        whitelist: whitelistStatus,
        search: searchKeyword,
        token,
      })
        .then((res) => {
          if(res.status === 'ok') {
            this.setState({ userList: res.data });
          } else {
            this.props.history.push('/signin');
          }
        })
        .catch((e) => {
          console.log(e);
          this.props.history.push('/signin');
        });
    });
  }

  hideWhitelistModal() {
    this.setState({ whitelistedUsers: null });
  }

  render() {
    const { userList, page, pageSize, selectedIndex, whitelistedUsers } = this.state;
    const userCount = userList ? userList.count : 0;
    return (
      <div className="block dashboard">
        <div>
          <div className="header">
            <div>
              <input
                className="search_input"
                placeholder="Search Email"
                onKeyDown={this.searchEmail}
                onChange={this.handleSearchChange}
                suffix={
                  <Icon style={{ fontSize: 16 }} type="search" />
                }
              />
            </div>
            <div className="csv_button_wrap">
              <button type="button" onClick={this.downloadUserList}>
                Download CSV
              </button>
            </div>
            <div className="contributions_button_wrap">
              <button type="button" onClick={this.downloadUserContributions}>
                Download With<br />Contributions
              </button>
            </div>
            <div className="validate_wrap">
              <button type="button" onClick={this.checkWhitelist}>
                Validate Whitelist
              </button>
            </div>
          </div>
          <div className="filters">
            <div>
              <label>Kyc Status: </label>
              <DropdownSelect
                defaultValue={this.state.kycfilterOption}
                onSelectItem={value => this.onSelectItem(value, 'kyc')}
                options={filterOptions}
              />
            </div>
            <div>
              <label>Whitelist Status: </label>
              <DropdownSelect
                defaultValue={this.state.whitefilterOption}
                onSelectItem={value => this.onSelectItem(value, 'whitelist')}
                options={filterOptions}
              />
            </div>
            <div className="row_count">
              <div className="row_count_text">Count: </div>
              <div className="row_count_value">{userCount}</div>
            </div>
          </div>
          <div className="dashboard_tb_header">
            <div>Email</div>
            <div>Name</div>
            <div>ETH Address</div>
            <div>KYC</div>
            <div>Whitelist</div>
            <div>DB Allocation</div>
          </div>
          <div className="main">
            <div className="dashboard_list">
              {
                userList ? (
                  userList.users.map((item, index) => (
                    <div key={(page - 1) * pageSize + index} className="user_item">
                      <ListItem
                        data={item}
                        itemIndex={(page - 1) * pageSize + index}
                        selectedIndex={selectedIndex}
                        sendWhitelist={this.sendWhitelist}
                        onSelectList={this.onSelectList}
                      />
                    </div>
                  ))) : 'No items to show...'
              }
            </div>
          </div>
          <div className="footer">
            <Pagination defaultCurrent={1} defaultPageSize={15} total={userList ? userList.total : 0} onChange={this.onChangePagination} />
          </div>
          { whitelistedUsers
            ? (
              <ValidateWhitelist
                users={whitelistedUsers}
                onClose={() => this.hideWhitelistModal()}
                sendWhitelist={this.sendWhitelist}
              />
            )
            : null
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, userlist }) => ({
  user: auth.user,
  token: auth.token,
  list: userlist.list,
});

const mapDisptachToProps = (dispatch) => {
  const {
    getUserList,
    getUserListCSV,
    getWhitelisted,
    postUserWhitelist,
  } = userListActionCreators;

  return bindActionCreators({
    getUserList,
    getUserListCSV,
    getWhitelisted,
    postUserWhitelist,
  }, dispatch);
};

export default compose(
  connectUserlist(mapStateToProps, mapDisptachToProps),
  connectAuth(mapStateToProps, undefined),
)(DashboardContainer);
