import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Form, Row, Col, Input, Layout } from 'antd';
import { connectAuth, authActionCreators } from 'core';
import promisify from '../../utilities';
import validateEmail from '../../services/common';

const { Header } = Layout;

class SignInContainer extends PureComponent {
  static propTypes = {
    login: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isFocus: false,
      msg: '',
    };
  }

  componentDidMount() {
  }

  handleEmail = () => {
    this.setState({ isFocus: true });
  }

  updateEmailValue = (evt) => {
    this.setState({
      email: evt.target.value,
    }, () => {
      if(this.state.email === '' || validateEmail(this.state.email)) {
        this.setState({ isEmailValidate: true });
      } else {
        this.setState({ isEmailValidate: false });
      }
    });
  }

  updatePasswordValue = (evt) => {
    this.setState({ password: evt.target.value });
  }

  showAdminDashboard = (e) => {
    e.preventDefault();
    if(this.state.email.length !== 0 && validateEmail(this.state.email)) {
      this.setState({ isEmailValidate: true });
      promisify(this.props.login, {
        email: this.state.email,
        password: this.state.password,
      })
        .then((res) => {
          if(res.status === 'ok') {
            if(res.data.user.isAdmin) {
              this.setState({ msg: '' });
              this.props.history.push('/dashboard');
            } else {
              this.setState({ msg: 'Invalid user type' });
            }
          }
        })
        .catch((error) => {
          console.log('error', error);
          this.setState({ msg: 'Invalid username / password' });
        });
    } else {
      this.setState({ isEmailValidate: false });
    }
  }

  render () {
    return (
      <div className="block">
        <Layout>
          <Header className="header" />
          <Layout>
            <Form className="main" onSubmit={this.showAdminDashboard}>
              <Row className="sign_logo_area" />
              <Row className="email_area">
                <Col offset={4} span={16}>
                  { this.state.isFocus
                    ? (
                      <span className="label_name">
                        { this.state.isEmailValidate ? 'Email Address' : 'Invalid Email'}
                      </span>
                    )
                    : null
                  }
                  <Input
                    type="email"
                    value={this.state.email}
                    placeholder="Email Address"
                    onChange={this.updateEmailValue}
                  />
                </Col>
              </Row>
              <Row className="password_area">
                <Col offset={4} span={16}>
                  <Input
                    type="password"
                    value={this.state.password}
                    placeholder="Password"
                    onChange={this.updatePasswordValue}
                  />
                </Col>
              </Row>
              <Row className="msg_area">
                <Col offset={5} span={16}>
                  { this.state.msg ? this.state.msg : '' }
                </Col>
              </Row>
              <Row>
                <Col offset={4} span={16}>
                  <Input type="submit" value="Sign In" className="continue_btn signin_btn" />
                </Col>
              </Row>
            </Form>
          </Layout>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});
const mapDisptachToProps = (dispatch) => {
  const {
    login,
  } = authActionCreators;

  return bindActionCreators({
    login,
  }, dispatch);
};

export default connectAuth(mapStateToProps, mapDisptachToProps)(SignInContainer);
