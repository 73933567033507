const auth = {
  user: {},
  token: '',
};

const userlist = {
  list: {},
};

export const initialState = {
  auth,
  userlist,
};
