import {
  put,
  call,
  fork,
  all,
  take,
} from 'redux-saga/effects';

import {
  userListActionCreators,
  USERLIST_REQUEST,
  USER_WHITELIST_REQUEST,
  USERLIST_CSV_REQUEST,
  WHITELIST_REQUEST,
  DOCUMENT_REQUEST,
  USER_STATUS_REQUEST,
} from './actions';

import apiService from '../../../services';

export function* asyncUserlistRequest({ payload, resolve, reject }) {
  const { kyc, whitelist, score, page, search, token } = payload;
  try {
    const response = yield call(apiService,
      {
        api: `users/list?kyc=${kyc}&whitelist=${whitelist}&score=${score}&search=${search}&page=${page}`,
        method: 'GET',
        params: {},
        token,
      });
    if(response.status === 'ok') {
      yield put(userListActionCreators.getUserListSuccess({ list: response.data }));
      resolve(response);
    } else {
      reject(response.msg);
    }
  } catch(e) {
    reject(e);
  }
}

export function* asyncUserlistCSVRequest({ payload, resolve, reject }) {
  const { kyc, whitelist, score, search, token } = payload;
  try {
    const response = yield call(apiService,
      {
        api: `users/download_csv?kyc=${kyc}&whitelist=${whitelist}&score=${score}&search=${search}`,
        method: 'GET',
        params: {},
        token,
        jsonResponse: false,
      });
    resolve(response);
  } catch(e) {
    reject(e);
  }
}

export function* asyncWhitelistRequest({ payload, resolve, reject }) {
  const { token } = payload;
  try {
    const response = yield call(apiService,
      {
        api: 'users/whitelisted',
        method: 'GET',
        params: {},
        token,
      });
    resolve(response);
  } catch(e) {
    reject(e);
  }
}

export function* asyncUserwhitelistRequest({ payload, resolve, reject }) {
  const { userid, bonusPercent, maxPurchaseAmount, token, force } = payload;
  try {
    const response = yield call(apiService,
      {
        api: 'users/whitelist',
        method: 'POST',
        params: {
          userid,
          bonusPercent,
          maxPurchaseAmount,
          force,
        },
        token,
      });
    if(response.status === 'ok') {
      resolve(response);
    } else {
      reject(response.message);
    }
  } catch(e) {
    reject(e);
  }
}

export function* asyncDocumentRequest({ payload, resolve, reject }) {
  const { token, useremail, type } = payload;
  const docType = type ? `&type=${type}` : '';
  try {
    const response = yield call(apiService,
      {
        api: `/admin/userdocuments?token=${token}&useremail=${useremail}${docType}`,
        method: 'GET',
        params: {},
      });
    if(response.status === 200) {
      resolve(response);
    } else {
      reject(response.msg);
    }
  } catch(e) {
    reject(e);
  }
}

export function* asyncUserStatusRequest({ payload, resolve, reject }) {
  const { token, useremail, approvalStatus, approvalDescription } = payload;
  try {
    const response = yield call(apiService,
      {
        api: '/admin/approve_user',
        method: 'POST',
        params: {
          token,
          useremail,
          approvalStatus,
          approvalDescription,
        },
      });
    if(response.status === 200) {
      resolve(response);
    } else {
      reject(response.msg);
    }
  } catch(e) {
    reject(e);
  }
}

export function* watchUserlistRequest() {
  while(true) {
    const action = yield take(USERLIST_REQUEST);
    yield* asyncUserlistRequest(action);
  }
}

export function* watchUserwhitelistRequest() {
  while(true) {
    const action = yield take(USER_WHITELIST_REQUEST);
    yield* asyncUserwhitelistRequest(action);
  }
}

export function* watchUserlistCSVRequest() {
  while(true) {
    const action = yield take(USERLIST_CSV_REQUEST);
    yield* asyncUserlistCSVRequest(action);
  }
}

export function* watchWhitelistRequest() {
  while(true) {
    const action = yield take(WHITELIST_REQUEST);
    yield* asyncWhitelistRequest(action);
  }
}

export function* watchDocumentRequest() {
  while(true) {
    const action = yield take(DOCUMENT_REQUEST);
    yield* asyncDocumentRequest(action);
  }
}

export function* watchUserStatusRequest() {
  while(true) {
    const action = yield take(USER_STATUS_REQUEST);
    yield* asyncUserStatusRequest(action);
  }
}

export default function* () {
  yield all([
    fork(watchUserlistRequest),
    fork(watchUserwhitelistRequest),
    fork(watchUserlistCSVRequest),
    fork(watchWhitelistRequest),
    fork(watchDocumentRequest),
    fork(watchUserStatusRequest),
  ]);
}
