import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { copyClipboard } from '../../utilities';

class ListItem extends PureComponent {
  static propTypes = {
    data: PropTypes.any.isRequired,
    selectedIndex: PropTypes.number.isRequired,
    itemIndex: PropTypes.number.isRequired,
    sendWhitelist: PropTypes.any.isRequired,
    onSelectList: PropTypes.any.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      isExpand: false,
      bonusPercent: 0,
      contributeAmount: 366,
      whitelistCode: 300,
      msg: '',

    };
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.selectedIndex !== nextProps.selectedIndex) {
      if(this.props.itemIndex === nextProps.selectedIndex) {
        this.setState({ isExpand: true });
      } else {
        this.setState({ isExpand: false });
      }
    }
  }

  showDetailItem = () => {
    const { itemIndex } = this.props;
    this.props.onSelectList(itemIndex);

    this.setState(prevState => (
      { isExpand: !prevState.isExpand }
    ));
  }

  forceWhitelist = () => {
    const { data, sendWhitelist } = this.props;
    const { contributeAmount, bonusPercent } = this.state;
    sendWhitelist(data.id, contributeAmount, bonusPercent, true);
  }

  firstWhitelist = () => {
    const { data, sendWhitelist } = this.props;
    const { contributeAmount, bonusPercent } = this.state;
    sendWhitelist(data.id, contributeAmount, bonusPercent, false);
  }

  updateInputValue = (evt, type) => {
    if(type === 'bonus') {
      this.setState({ bonusPercent: evt.target.value });
    } else {
      this.setState({ contributeAmount: evt.target.value });
    }
  }

  render() {
    const { data } = this.props;
    if(!data) return null;
    const firstname = data.firstname || '';
    const lastname = data.lastname || '';
    const name = firstname ? `${firstname} ${lastname}` : lastname;

    return (
      <div>
        <div className="item">
          <div className="item_header" role="presentation" onClick={this.showDetailItem}>
            <div className="item_email">{data.email || '-'}</div>
            <div className="item_name">{name || '-'}</div>
            <div className="item_address">{data.address || '-'}</div>
            <div>
              { data.kyc
                ? <Icon type="check" style={{ color: 'green' }} />
                : <Icon type="close" style={{ color: 'red' }} />}
            </div>
            <div>
              { data.whitelist
                ? <Icon type="check" style={{ color: 'green' }} />
                : <Icon type="close" style={{ color: 'red' }} />}
            </div>
            <div className="item_allocation" role="presentation" onClick={e => e.stopPropagation()}>
              {data.maxPurchaseAmount
                ? <a href={`https://etherscan.io/tx/${data.whitelistTxHash}`} target="_blank" rel="noopener noreferrer">{data.maxPurchaseAmount}</a>
                : '-'
              }
            </div>
          </div>
          { this.state.isExpand
            ? (
              <div className="item_expanded">
                <div className="item_detail_area">
                  <div className="kyc_key">
                    {data.verificationSessionToken
                      ? (
                        <div
                          onClick={() => copyClipboard(data.verificationSessionToken)}
                          role="presentation"
                          className="copyable"
                        >
                          KYC Session Key
                        </div>
                      )
                      : 'No KYC Session Key'}
                  </div>
                </div>
                <div className="item_inputs">
                  <div>
                    <label htmlFor="max_purchase">
                      Contribution Amount(Eth):&nbsp;
                      <input
                        className="max_purchase_input"
                        id="max_purchase"
                        type="text"
                        value={this.state.contributeAmount}
                        onChange={evt => this.updateInputValue(evt, 'contribute')}
                      />
                    </label>
                  </div>
                  <div>
                    <label htmlFor="bonusPercent">
                      Bonus percent:&nbsp;
                      <input
                        className="bonusPercent_input"
                        id="bonusPercent"
                        type="text"
                        value={this.state.bonusPercent}
                        onChange={evt => this.updateInputValue(evt, 'bonus')}
                        defaultValue={data.bonusPercent || 0}
                      />
                    </label>
                  </div>
                </div>
                <div className="item_actions">
                  <div>
                    <button
                      type="button"
                      onClick={() => this.firstWhitelist()}
                      disabled={data.whitelist || (!data.whitelist && data.whitelistTxHash)}
                    >
                      Whitelist
                    </button>
                  </div>
                  { ((!data.whitelist && data.whitelistTxHash)
                      || (data.maxPurchaseAmount < 20 && data.whitelist)
                  )
                    ? (
                      <div className="pending_wrap">
                        <span>
                          <a href={`https://etherscan.io/tx/${data.whitelistTxHash}`} target="_blank" rel="noopener noreferrer">
                            Pending:&nbsp;
                          </a>
                        </span>
                        <button type="button" onClick={() => this.forceWhitelist()}>
                          Force Retry
                        </button>
                      </div>
                    )
                    : '' }
                </div>
                <div className="item_whitelisting">
                  <div>
                    {this.state.whitelistCode === 200
                      ? (
                        <p className="success">
                          <a
                            href={`https://etherscan.io/tx/${this.state.msg}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {this.state.msg}
                          </a>
                        </p>
                      )
                      : <p className="fail">{this.state.msg}</p>}
                  </div>
                </div>
              </div>
            )
            : null
          }
        </div>
      </div>
    );
  }
}

export default ListItem;
