import {
  put,
  call,
  fork,
  all,
  take,
} from 'redux-saga/effects';

import {
  authActionCreators,
  LOGIN_REQUEST,
} from './actions';

import apiService from '../../../services';

export function* asyncLoginRequest({ payload, resolve, reject }) {
  const { email, password } = payload;
  try {
    const response = yield call(apiService,
      {
        api: 'auth/login',
        method: 'POST',
        params: {
          email,
          password,
        },
      });
    // @TODO: Open next lines after login api is completed
    if(response.status === 'ok') {
      yield put(authActionCreators.loginSuccess({ user: response.data }));
      resolve(response);
    } else {
      reject(response.message);
    }
  } catch(e) {
    reject(e);
  }
}

export function* watchLoginRequest() {
  while(true) {
    const action = yield take(LOGIN_REQUEST);
    yield* asyncLoginRequest(action);
  }
}

export default function* () {
  yield all([
    fork(watchLoginRequest),
  ]);
}
