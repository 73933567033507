import {
  USERLIST_REQUEST_SUCCESS,
} from './actions';
import { initialState } from '../initialState';

export default function userlist(state = initialState.userlist, action = {}) {
  const { type, payload } = action;

  switch(type) {
    case USERLIST_REQUEST_SUCCESS: {
      return {
        ...state,
        list: payload.list,
      }
    }
    default: {
      return state;
    }
  }
}
