import { combineReducers } from 'redux';

import {
  auth,
  userlist,
} from '../modules';

const rootReducer = combineReducers({
  auth,
  userlist,
});

export default rootReducer;
