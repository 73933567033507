import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connectAuth } from 'core';

const PrivateRoute = (props) => {
  const { token, ...otherProps } = props;
  if(token) {
    return (
      <Route {...otherProps} />
    );
  }
  return (
    <Redirect to="/signin" />
  );
};

PrivateRoute.propTypes = {
  token: PropTypes.string,
};

const mapStateToProps = ({ auth }) => ({
  token: auth.token,
});
export default connectAuth(mapStateToProps, {})(PrivateRoute);
