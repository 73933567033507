import React from 'react';

// eslint-disable-next-line no-unused-vars
const PageNotFound = ({ ...props }) => (
  <div className="">
    404 Error
    <br />
    Page Not Found
  </div>
);

export default PageNotFound;
