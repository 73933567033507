import { createAction } from 'redux-actions';
import createPromiseAction from '../utils';

/**
 * Action Types
 */

export const USERLIST_REQUEST = 'userlist/USERLIST_REQUEST';
export const USERLIST_REQUEST_SUCCESS = 'userlist/USERLIST_REQUEST_SUCCESS';
export const USER_WHITELIST_REQUEST = 'userlist/USER_WHITELIST_REQUEST';
export const USERLIST_CSV_REQUEST = 'userlist/USERLIST_CSV_REQUEST';
export const WHITELIST_REQUEST = 'userlist/WHITELIST_REQUEST';
export const DOCUMENT_REQUEST = 'document/DOCUMENT_REQUEST';
export const DOCUMENT_REQUEST_SUCCESS = 'document/DOCUMENT_REQUEST_SUCCESS';
export const USER_STATUS_REQUEST = 'user/STATUS_UPDATE_REQUEST';
export const USER_STATUS_REQUEST_SUCCESS = 'user/STATUS_UPDATE_REQUEST_SUCCESS';
/**
 * Action Creators
 */
export const userListActionCreators = {
  getUserList: createPromiseAction(USERLIST_REQUEST),
  getUserListSuccess: createAction(USERLIST_REQUEST_SUCCESS),
  postUserWhitelist: createPromiseAction(USER_WHITELIST_REQUEST),
  getUserListCSV: createPromiseAction(USERLIST_CSV_REQUEST),
  getWhitelisted: createPromiseAction(WHITELIST_REQUEST),
  getUserDocument: createPromiseAction(DOCUMENT_REQUEST),
  getUserDocumentSuccess: createPromiseAction(DOCUMENT_REQUEST_SUCCESS),
  updateUserStatus: createPromiseAction(USER_STATUS_REQUEST),
  updateUserStatusSuccess: createPromiseAction(USER_STATUS_REQUEST_SUCCESS),
};
