import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

class DropdownSelect extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    defaultValue: PropTypes.string.isRequired,
    options: PropTypes.any.isRequired,
    onSelectItem: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: '',
  }

  onChangeItem = (value) => {
    this.props.onSelectItem(value);
  }

  render() {
    const {
      className,
      defaultValue,
      options,
      ...props
    } = this.props;

    return (
      <Select
        {...props}
        className={`nrl-dropdown${className ? ` ${className}` : ''}`}
        size="large"
        onChange={value => this.onChangeItem(value)}
        value={defaultValue}
      >
        {
          (options && options.length) && (
            options.map((item, index) => (
              item.name
                ? (
                  <Select.Option
                    key={index.toString()}
                    value={item.name}
                  >
                    {item.name}
                  </Select.Option>
                ) : null
            ))
          )
        }
      </Select>
    );
  }
}

export default DropdownSelect;
