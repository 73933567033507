
export { default as Web3 } from 'web3';

export default function promisify(fn, ...args) {
  return new Promise((resolve, reject) => {
    fn.apply(null, [...args, resolve, reject]);
  });
}

export function copyClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('Copy');
  textArea.remove();
}
