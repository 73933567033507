import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FileDownload from 'js-file-download';
import { Web3 } from '../../utilities';
import { WHITELIST_ADDRESS } from '../../services/constants';

class ValidateWhitelist extends PureComponent {
  static propTypes = {
    users: PropTypes.any.isRequired,
    onClose: PropTypes.func.isRequired,
    sendWhitelist: PropTypes.any.isRequired,
  }

  constructor(props) {
    super(props);
    this.report = [];
    this.state = {
      error: null,
      loading: true,
      index: 0,
    };
  }

  componentDidMount() {
    if(typeof window.ethereum !== 'undefined') {
      this.web3 = new Web3(window.ethereum);
      if(window.ethereum.networkVersion !== '1') {
        this.setState({ error: 'Switch MetaMask to mainnet and try again' });
      } else {
        this.doValidation();
      }
    } else {
      this.setState({ error: 'MetaMask required' });
    }
  }

  getTransaction(txHash) {
    const self = this;
    return new Promise((resolve, reject) => {
      self.web3.eth.getTransaction(txHash, (error, result) => {
        if(error) {
          reject(new Error(error));
        } else {
          resolve(result);
        }
      });
    });
  }

  async doValidation() {
    const users = this.props.users.users; // eslint-disable-line
    const self = this;

    const addReport = (u, msg, allocation = null, bonus = null) => {
      self.report.push([
        u.id, u.email || ' ', u.address || ' ', u.whitelistTxHash || ' ',
        u.allocation || ' ', allocation || '?', bonus || '?', msg || ' ',
      ]);
    };
    let errors = 0;
    for(let n = 0; n < users.length; n += 1) {
      const user = users[n];
      const txHash = user.whitelistTxHash;
      if(txHash !== null && txHash !== '') {
        let tx = null;
        try {
          tx = await this.getTransaction(txHash); // eslint-disable-line
        } catch(error) {
          console.log(error);
        }
        if(tx) {
          if(tx.input && tx.input.length >= 128) {
            let allocation = tx.input.slice(tx.input.length - 64, tx.input.length);
            let bonus = tx.input.slice(tx.input.length - 128, tx.input.length - 64);
            bonus = parseInt(bonus, 16);
            allocation = parseInt(allocation, 16) / 1e18;
            if(bonus > 0) {
              errors += 1;
              addReport(user, 'Nonzero bonus', allocation, bonus);
            }
            // addReport(user, 'OK', allocation);
            this.setState({ index: n });
          } else {
            console.log(tx);
            errors += 1;
            addReport(user, `Bad TX input ${tx.input.length}`);
          }
        } else {
          errors += 1;
          addReport(user, 'Invalid TX hash');
        }
      } else {
        errors += 1;
        addReport(user, 'No TX hash');
      }
    }
    this.setState({ loading: false, error: errors ? `${errors} inconsistencies found.` : null });
  }

  download() {
    const csv = this.report.map(item => (
      item.join()
    ));
    FileDownload(csv.join('\n'), 'whitelist-issues.csv');
  }

  render() {
    const { users, onClose, sendWhitelist } = this.props;
    const { loading, index, error } = this.state;

    return (
      <div className="modal">
        <div className="modal-content">
          <h2>Validate Whitelist</h2>
          <div className="modal-subtitle">
            This will check all whitelisted users stored in the database against the&nbsp;
            whitelist smart contract at&nbsp;
            {WHITELIST_ADDRESS}
          </div>
          <div className="main">
            <div className="dashboard_list">
              {
                !loading && (
                  this.report.map((item, idx) => (
                    <div key={idx.toString()} className="user_item">
                      <div className="item_email">{item[1] || '-'}</div>
                      <div className="item_address">{item[2] || '-'}</div>
                      <div className="item_allocation">
                        <a href={`https://etherscan.io/tx/${item[3]}`} target="_blank" rel="noopener noreferrer">
                          TX Receipt
                        </a>
                      </div>
                      <div className="item_allocation">
                        {item[4] || '?'} / {item[5] || '?'}
                      </div>
                      <div className="item_allocation">
                        {item[6] || '?'}
                      </div>
                      <div className="item_address">{item[7] || '-'}</div>
                      <button type="button" onClick={() => sendWhitelist(item[0], 366, 0, true)}>
                        Retry
                      </button>
                    </div>
                  )))
              }
            </div>
          </div>
          <div className="modal-data-wrap">
            { loading
              ? (
                <div className="modal-loading">
                  Checking user:&nbsp;
                  <span>{index + 1} / {users.count}</span>
                </div>
              )
              : (
                <div>
                  Got users
                </div>
              )}
          </div>
          { error && <div className="modal-error">{error}</div> }
          { (!loading && this.report.length === 0)
            && <div className="modal-success">No inconsistencies found!</div>
          }
          <div className="modal-actions">
            {(!loading && this.report.length !== 0)
              && <button type="button" onClick={() => this.download()}>Download</button>
            }
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }
}

export default ValidateWhitelist;
